@import "../variables";
@import "../template/mixin_templates";
@import url('https://fonts.googleapis.com/css?family=Raleway:400,600,700');

/* Variables */
$largeur_fenetre_grand: $xlarge-screen - 80;

/***************************************************/
/******************** ANIMATIONS *******************/
/***************************************************/

@keyframes block-search {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes wrapimage-nav-anim {
    0% {
        visibility: visible;
        opacity: 0;
    }
    1% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}

/***************************************************/
/********************* GENERAL *********************/
/***************************************************/

body, html {
    font-size: 16px;
    font-family: 'Raleway', sans-serif;
    margin: 0;
    box-sizing: border-box;
}

body{
    overflow-x: hidden;
}

*, *:before, *:after {
    box-sizing: inherit;
}

@include titreBaseSize();

h2, .wrap-details > h4, .h2, .wrap-details > .h4, .title_section {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: block;
    position: relative;
    padding-bottom: 15px;

    &::after {
        content: '';
        height: 3px;
        width: 120px;
        position: absolute;
        display: block;
        bottom: 0;
        left: 0;
    }
}

h2.titre-article::after, .h2.titre-article::after {
    display: none;
}

#bloc-fil-title h2, #bloc-fil-title .h2,
#bloc-fil-title h1, #bloc-fil-title .h1 {
    margin-top: 0;
    clear: both;
}

a {
    text-decoration: none;
    img {
        border: none;
    }
}

.contenu-editable a {
    text-decoration: underline;
    &.button {
        text-decoration: none;
    }
}

img {
    max-width: 100%;
    height: auto !important;
}

ul {
    padding: 0;
    list-style: none;
    list-style-position: inside;
}

iframe {
    max-width: 100%;
}

table {
    position: relative;
    table-layout: fixed;
    max-width: 100% !important;
}


input[type="text"], input[type="password"], select, textarea {
    height: 40px;
    padding: 0 12px;
    font-size: 14px;
    border-width: 1px;
    border-style: solid;
    line-height: 40px;
    border-radius: 5px;
}

select[multiple]{
    min-width: 126px;
    height: 60px;
}

.block-reduction-code .input-group {
    input[type=text] {
        width: 98%;
    }
}

.button {
    position: relative;
    box-sizing: border-box;
    padding: 10px 25px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    display: inline-block;
    margin: 10px 0;
    border-radius: 20px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
    &.button-default {
        border-radius: 0;
    }
}

/* Conteneur principal */
.wrapper, #headercontent {
    margin: 0 auto;
    position: relative;
}

.wrapper-content {
    position: relative;
    //z-index: 1;
}

#wrapper, #pub {
    width: 1200px;
    margin: 0 auto;
    padding: 0 0;
    &::after {
        content: '';
        display: block;
        clear: both;
    }
}

.wrapper {
    //z-index: 1;
}

.content {
    padding: 20px;
}

.content_p {
    position: relative;
}

#main-conteneur {
    //Pose un problème en responsive/sous menu : si la hauteur du contenu n'est pas très grande alors le menu peut être coupé
    //overflow: hidden;
}

.page {
    box-sizing: border-box;
}

.modif-design #minisite-sidebar {
    display: none;
}

#wrapper .sidebar.event {
    border: none;

    nav.side-tabs.top > a {
        width: 100%;
    }
}

/**************************************************/
/********************* HEADER *********************/
/**************************************************/

header {
    position: relative;
    //z-index: 2;
    height: 150px;
    font-size: 0;
    text-align: center;
    &.header_p {
        background-repeat: no-repeat;
    }
}

#blocContent {
    width: 100%;
    position: absolute;
    height: 100%;
    font-size: 13px;
    text-align: left;
    float: left;
    //z-index: 1;
}

#headercontent {
    width: 100%;
    height: 100%;
    //overflow: hidden;

    .container-slogan {
        position: absolute;
        width: 1200px;
        bottom: 0;
        height: 100%;
    }
}

.slogan {
    margin: 0;
    position: absolute;
    padding-bottom: 5px;
    font-weight: 500;
    top: 0;
    z-index: 2;
}

.header-conteneur {
    width: 1200px;
    margin: 0 auto;
    height: 100%;
    position: relative;
    z-index: 1;
}

.logo {
    display: inline-block;
    position: absolute;
    z-index: 1;

    img {
        max-height: 150px;
        max-width: 100%;
    }
}

/************************************************/
/********************* MENU *********************/
/************************************************/

.header-conteneur {
    .menu {
        margin-top: 48px;
        box-sizing: border-box;
        display: inline-block;
        position: absolute;
        right: 0;
        float: right;
        z-index: 3;
    }
}

//Menu Principal

nav#nav-principal {
    position: relative;
    //z-index: 2;
    margin: auto;
    > ul {
        font-size: 0;
        text-align: right;
        position: relative;
        ul {
            visibility: hidden;
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 150;
            width: 100%;
            min-width: 185px;
            margin-top: 25px;
            text-align: left;
            opacity: 0;
            li {
                display: block;
                span {
                    white-space: nowrap;
                }
                &:hover > ul {
                    visibility: visible;
                    opacity: 1;
                    margin-left: 0;
                    transition-delay: 0s;
                }
            }
            ul {
                left: 95%;
                top: 0;
                margin: 0 0 0 25px;
                transition: margin-left .2s ease-out, opacity .2s ease-out, visibility 0s ease-out .2s;
            }
        }
    }

    > ul {
        > li > a {
            padding: 18px 0;

            > span {
                padding: 0 10px;
            }
        }

        > li:last-child > a > span {
            border: none;
        }
    }

    li {
        display: inline-block;
        position: relative;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: bold;
        padding: 0;

        &:hover {
            > ul {
                visibility: visible;
                opacity: 1;
                margin-top: 0;
                transition-delay: 0s;
            }
        }

        a {
            padding: 18px 10px;
            width: 100%;
            display: block;
        }

        > ul {
            box-sizing: border-box;
            width: auto;
            max-width: none;
            box-shadow: 0 0 5px #999;
            transition: margin-top .2s ease-out, opacity .2s ease-out, visibility 0s ease-out .2s;

            li {
                padding: 0 15px;

                a {
                    padding: 8px 10px;
                }

                &:first-child {
                    > a {
                        padding-top: 15px;
                    }
                }

                &:last-child {
                    padding-bottom: 15px;
                }
            }
        }
    }
}

//Menu secondaire

.wrapper-content {
    .menu {
        box-shadow: -1px 3px 3px 0 rgba(0, 0, 0, 0.2);
        position: relative;
        //z-index: 2;
        margin-bottom: 5px;
    }
}

#nav-secondary {
    min-height: 50px;
    width: 1200px;
    margin: 0 auto;
    position: relative;
    transition: height .1s linear;
    text-align: center;

    &:empty {
        min-height: 5px;
    }

    li a > span {
        white-space: nowrap;
        display: block;
        padding: 0 20px;
    }

    ul {
        text-align: left;
        box-sizing: border-box;
        box-shadow: 0 0 5px #999;
        //z-index: 2;
        margin: 0;
        font-size: 0;
    }

    > ul {
        max-width: 100%;
        box-shadow: none;
        float: left;
        > li {
            height: 48px;
            line-height: 48px;
            margin-top: 1px;
            margin-left: 1px;
            padding: 0;
            &:first-child {
                margin-left: 0;
            }
        }
        ul {
            z-index: 150;
        }
    }

    li {
        height: 50px;
        box-sizing: border-box;
        padding: 0 10px;
        line-height: 50px;
        transition: all .2s;
        font-weight: 600;
        font-size: 14px;
        text-transform: capitalize;
        position: relative;
        display: inline-block;

        > span {
            float: right;
            position: absolute;
            top: 0;
            right: 30px;
            transition: all .1s linear;
        }

        li {
            display: block;
            width: 100%;
        }

        ul {
            position: absolute;
            top: 100%;
            left: 0;
            min-width: 150px;
            width: auto;
            box-shadow: 2px 2px 5px #999;
            margin-top: 25px;
            visibility: hidden;
            opacity: 0;
            transition: margin-top .2s ease-out, opacity .2s ease-out, visibility 0s ease-out .2s;

            ul {
                margin-top: 0;
                margin-left: 25px;
                top: 0;
                left: 100%;
                transition: margin-left .2s ease-out, opacity .2s ease-out, visibility 0s ease-out .2s;
            }
            li:hover {
                > ul {
                    visibility: visible;
                    opacity: 1;
                    margin-left: 0;
                    transition-delay: 0s;
                }
            }
        }

        &:hover {
            > span {
                transform: rotate(-90deg);
            }
            > ul {
                visibility: visible;
                opacity: 1;
                margin-top: 0;
                transition-delay: 0s;
            }
        }
    }
    a {
        transition: all .2s;
        width: 100%;
        display: block;
    }
    &::after {
        content: '';
        display: block;
        clear: both;
    }
}

//Modification de la taille en fonction des éléments dans la barre de navigation secondaire
body {
    //Si que la barre de recherche
    &.search-button #nav-secondary {
        > ul {
            max-width: calc(100% - 310px);
        }
    }
    //Si que le template 4 menu
    &.cmonsite-panier-4 #nav-secondary {
        > ul {
            max-width: calc(100% - 110px);
        }
    }
    //Si template 4 panier + barre de recherche
    &.cmonsite-panier-4.search-button #nav-secondary {
        > ul {
            max-width: calc(100% - 420px);
        }
    }
}

//Colonne de gauche

.sidebar {
    width: 225px;
    margin: 20px 0 0 20px;
    padding: 0;
    float: left;
    &.with-arianne {
        margin: 60px 0 0 20px;
    }
    ~ .content {
        width: 955px;
        float: right;
        padding: 20px;

        nav.nav-static-breadcrumb {
            margin-left: -245px;
        }
    }
}

#nav-static-principal > ul {
    //Pour passer au dessus du menu burger
    display: block !important;
    margin: 0;

    .dropDown {
        position: absolute;
        height: 39px;
        width: 39px;
        line-height: 39px;
        color: inherit;
        right: 0;
        top: 0;
        text-align: center;
        cursor: pointer;
    }
    li {
        position: relative;
        li ul {
            display: none;
            li > a {
                padding: 10px 25px;
            }
        }
    }
    li.is-actif {
        > ul {
            //Pour passer au dessus du menu burger
            display: block !important;
        }
        > .dropDown {
            //Pour passer au dessus du menu burger
            display: none !important;
        }
    }
    > li > a {
        text-transform: uppercase;
        font-weight: 700;
    }
    a {
        padding: 10px;
        display: block;
        border-bottom: 1px solid;
        font-size: 14px;
        font-weight: 600;
        text-transform: capitalize;

        &::before {
            display: none;
        }
    }
}

//Fil d'ariane

.nav-static-breadcrumb {
    font-size: 0;
    text-transform: uppercase;
    height: 40px;
    line-height: 20px;
    li {
        display: inline-block;
        font-size: 13px;
        font-weight: 600;
        &:not(:first-child)::before {
            content: '\f105';
            font-family: fontAwesome, sans-serif;
            display: inline-block;
            margin: 0 10px;
        }
    }
}

/******************************************************/
/********************* DIAPORAMAS *********************/
/******************************************************/

.bx-wrapper .bx-viewport {
    left: 0;
}

.bx-controls {
    .bx-controls-direction {
        a.bx-prev, a.bx-next {
            margin-left: 5px;
            background: none;
            text-indent: 0;
            height: 50px;
            width: 50px;
            visibility: hidden;
            transition: all .1s ease-out;
            font-size: 0;
            &::before {
                font-family: fontAwesome, sans-serif;
                font-size: 16px;
                width: 50px;
                display: block;
                height: 50px;
                line-height: 44px;
                text-align: center;
                border: 3px solid rgba(0, 0, 0, 0.2);
                border-radius: 50%;
                color: rgba(0, 0, 0, 0.2);
            }
        }
        a.bx-prev {
            &::before {
                content: '\f0d9';
            }
        }
        a.bx-next {
            &::before {
                content: '\f0da';
            }
        }
    }
    .bx-page {
        .bx-default-pager {
            a.active, a:focus, a:hover {
                width: 35px;
                transition: all .2s ease-out;
            }
        }
    }
}

.accueil_diaporama_template {
    height: auto;
    margin-bottom: 0;
    width: 1200px;
    background: transparent !important;
    position: relative;
    overflow: hidden;
    padding: 0;
    .bx-wrapper {
        margin-bottom: 0;
        .bx-pager {
            bottom: 15px;
            z-index: 10;
        }
    }
    .bx-wrapper .bx-viewport, .slide > div, .slide > li, .center-img-slider img {
        max-height: 500px;
    }
    .bx-controls {
        .bx-controls-auto {
            display: none;
        }
    }
    .slide {
        .slide-text a.addcommentaires,
        .slide-text a.slider-see-more.button {
            border-radius: 50px;
            right: 40px;
        }
        .slide-text a.slider-see-more.button {
            bottom: 40px;
        }
        a.slider-see-more {
            border: none;
            border-radius: 0;
        }
    }

    .center-img-slider {
        margin: 0;
    }

    &:not(.accueil_diaporama_template_2) .bx-controls {
        width: 1200px;
        z-index: 11;
    }
    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        height: 100%;
        margin-left: -5000px;
        width: 10000px;
        background: rgba(0, 0, 0, 0.05);
    }
    &:hover {
        .bx-controls {
            .bx-controls-direction a.bx-prev, .bx-controls-direction a.bx-next {
                visibility: visible;
            }
        }
    }
    &:hover {
        .bx-controls-direction a.bx-prev {
            margin-left: 20px;
            visibility: visible;
            animation: wrapimage-nav-anim .2s ease-out;
        }

        .bx-controls-direction a.bx-next {
            margin-right: 20px;
            visibility: visible;
            animation: wrapimage-nav-anim .2s ease-out;
        }
    }
    @media #{$small-and-down} {
        .bx-controls-direction a.bx-prev {
            margin-left: 20px;
            visibility: visible;
            animation: wrapimage-nav-anim .2s ease-out;
        }

        .bx-controls-direction a.bx-next {
            margin-right: 20px;
            visibility: visible;
            animation: wrapimage-nav-anim .2s ease-out;
        }
    }

    &.accueil_diaporama_template_1 {
        .bx-viewport, .slide {
            max-width: 100%;
        }
        .bx-controls {
            height: auto;
            top: auto;
        }
        .slide .content-slide-text a.slider-see-more {
            &, & p {
                top: 80px;
                bottom: auto;
            }
        }
        .content-slide-text {
            > p {
                margin: 0 0 25px 0;
            }
        }
    }
    &.accueil_diaporama_template_2 {
        .content-slide-text {
            opacity: 0;
            a {
                padding: 20px;
            }
            p {
                left: 0;
                padding-left: 150px;
                max-width: 70%;
                font-size: 20px;
            }
        }
        .slide, .center-img-slider{
            height: 500px;
        }
        .bx-wrapper .content-slide-text {
            opacity: 1;
        }
    }
    &.accueil_diaporama_template_2,
    &.accueil_diaporama_template_4 {
        width: 100%;
    }
    &.accueil_diaporama_template_3 {
        .content-slide-text {
            > p {
                bottom: 80px;
            }
            > a.slider-see-more.button {
                bottom: auto;
                top: 80px;
            }
        }
        #bx-pager {
            max-width: 1200px;
            padding: 0 400px;
            margin: -62px auto 0;
            z-index: 5;
            position: relative;
            background: rgba(0, 0, 0, 0.5);
            display: none;
        }
        &:hover #bx-pager {
            display: block;
        }
    }
    &.accueil_diaporama_template_4 {
        margin-top: 0;
        max-height: 500px;
        padding: 0;
        .slide {
            li {
                max-height: 500px;
                .center-img-slider {
                    font-size: 0;
                    margin: 0;
                    img {
                        min-height: 500px;
                        max-height: 500px;
                    }
                }
            }
        }
        .content-slide-text {
            p {
                margin: 0;
            }

            > a.slider-see-more {
                bottom: 50px;
                right: 20px;
            }

            > a.addcommentaires {
                bottom: 120px;
                right: 20px;
                top: auto;
                border-radius: 50px;
                padding: 15px;
            }
        }
    }
    &.accueil_diaporama_template_5 {
        height: 300px;
        .slide .slide-text .content-slide-text {
            p {
                bottom: 0;
                left: 0;
                margin: 0;
            }
            a.addcommentaires {
                right: 20px;
            }
            > a.slider-see-more.button {
                bottom: auto;
                top: 60px;
                right: 20px;
            }
        }
    }
    &.accueil_diaporama_template_6 {
        overflow: hidden;
    }
}

/****************************************************/
/********************* PRODUITS *********************/
/****************************************************/

/*General*/
.produits {
    position: relative;
    //z-index: 1;
    .remise {
        position: absolute;
        z-index: auto;
        left: auto;
        right: 0;
        margin-top: 0;
        padding: 10px;
    }
    .productImageWrap {
        position: relative;
        width: 100%;
        overflow: hidden;
    }
    .mask {
        position: absolute;
        width: 100%;
        a {
            opacity: 0;
        }
    }
    a.zoom {
        border:none;
        opacity: 0;
        &::before {
            text-align: center;
        }
    }
    .prix {
        .oldprix {
            text-decoration: line-through;
        }
    }
    &:hover {
        .mask a {
            opacity: .5;
        }
        a.zoom {
            opacity: .5;
        }
    }
    .attr {
        select {
            padding: 0 10px;
            height: 30px;
        }
    }
    .nomprod a:hover {
        color: inherit;
    }
}

.produit_etiquette, .produit_etiquette2, .produit_etiquette3 {
    width: 100%;
    max-width: 100% !important;
    h3, .h3 {
        padding: 8px 0;
        font-size: 1.1rem;
    }
    &::after {
        display: none;
    }
}

//Templates

.template_boutique_all {
    .produits {
        display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
        .container-etiquette {
            width: 100%;
            .produit_etiquette::after {
                display: none;
            }
        }
        .remise {
            top: 60px;
            right: 10px;
            span {
                font-size: 17px;
                font-weight: 700;
            }
        }
        .productImageWrap a img {
            transition: all .2s ease-out;
        }
        &:hover .productImageWrap a img {
            transform: scale(1.05);
        }
        .productImageWrap {
            overflow: hidden;
        }
        .nomprod {
            text-align: center;
            justify-content: center;
            width: 100%;
            margin: 10px 0;
            font-size: 20px;
            display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
            order: 2;
        }
        .prix {
            text-align: center;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: 10px 0;
            //Passer au-dessus du js
            display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
            order: 3;
            line-height: 30px;
            .prixprod {
                margin: 0;
                min-width: 90px;
            }
            .oldprix {
                float: left;
                line-height: 30px;
                margin: 0 15px 0 0;
                min-width: 90px;
            }
        }
        .desc {
            font-size: 14px;
        }
        .attr {
            opacity: 0;
            display: flex;
            order: 1;
            //Passer au-dessus du js
            height: 30px !important;
            margin: -35px auto 15px;
            float: none;
            position: relative;
            z-index: 2;
        }
        .block-btn-addbasket{
            width: 100%;
            margin: 10px 0;
            display: none;
            position: relative;
        }
        a.addbasket {
            box-sizing: border-box;
            padding: 0;
            line-height: 20px;
            font-weight: bold;
            font-size: 13px;
            text-transform: capitalize;
            border: none;
            float: left;
            .inside-addbasket {
                padding: 8px 15px;
                border-radius: 20px;
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: 20px;
                    z-index: 1;
                }
                &:hover::before {
                    transform: scale(1.01);
                    transition: transform .2s cubic-bezier(.46, 15, .4, 15);
                }
                .fa, span {
                    z-index: 2;
                    position: relative;
                }
                .fa {
                    margin-right: 5px;
                }
            }
        }
        a.addbasket, .hors-stock {
            //Passer au-dessus du js
            line-height: 20px;
            padding: 0;
            font-size: 13px;
            width: auto;
            margin: 0 auto;
            max-width: 90%;
        }
        .productContentWrap {
            display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
        }
        &:hover {
            .mask .zoom, .productImageWrap .zoom {
                opacity: .2;
            }
            .prix {
                display: none;
            }
            .attr {
                opacity: 1;
            }
            .block-btn-addbasket {
                display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
                order: 4;
            }
            a.addbasket {
                /* Hack pour passer au dessus du static !important template boutique 5 */
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: 20px;
                    z-index: 1;
                }
                &:hover::before {
                    transform: scale(1.01);
                    transition: transform .2s cubic-bezier(.46, 15, .4, 15);
                }
                .fa, span {
                    z-index: 2;
                    position: relative;
                }
                .fa {
                    margin-right: 5px;
                }
            }
        }
        .note-produit {
            order: 3;
            text-align: center;
            width: 100%;
        }
    }
}

.template_boutique{
    &.template_boutique_1 {
        .produits {
            .addbasket, .hors-stock {
                width: auto;
                &.next-select {
                    margin: 10px auto;
                }
            }
        }
    }
    &.template_boutique_2 {
        .produits {
            .remise {
                top: 60px;
                span {
                    font-size: 17px;
                    font-weight: 600;
                }
            }
            .attr select {
                width: auto;
                right: auto;
            }
        }
    }
    &.template_boutique_3 {
        .produits {
            padding: 0;
            display: block;
            width: 100%;
            box-sizing: border-box;
            margin: 20px 0;
            border: 1px solid rgba(0, 0, 0, 0.1);
            .prix {
                .oldprix {
                    float: none;
                }
            }
            &:first-child {
                margin-top: 0;
            }
            .container-etiquette {
                width: 33%;
                .produit_etiquette {
                    position: relative;
                }
            }
            .remise {
                left: 0;
                right: auto;
                top: 0;
                span {
                    font-size: 17px;
                    font-weight: 700;
                }
            }
            &:hover .prix {
                display: flex;
            }
            .productImageWrap {
                width: 33%;
                float: left;
                border-right: 1px solid rgba(0, 0, 0, 0.1);
                overflow: hidden;
            }
            .productContentWrap {
                width: 67%;
                float: left;
                box-sizing: border-box;
                padding: 0 20px;
            }
            .nomprod {
                margin-top: 35px;
                text-align: left;
            }
            .desc {
                display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
                order: 2;
                margin: 15px 0;
            }
            .prix {
                font-size: 18px;
                margin: 0;
            }
            .attr {
                display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
                order: 3;
                justify-content: center;
                text-align: center;
                width: 100%;
                height: 30px;
                margin: 10px 0;
                opacity: 1;
            }
            .block-btn-addbasket{
                display: flex;
                order: 4;
            }
            a.addbasket, .hors-stock {
                position: relative;
                bottom: auto;
                top: auto;
                right: auto;
                left: auto;
                display: block;
            }
            &.is_etiquette {
                .nomprod {
                    margin-top: 0;
                }
            }
            &:hover {
                .addbasket {
                    order: 4;
                    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
                }
                .attr {
                    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
                    order: 3;
                }
            }
        }
    }
    &.template_boutique_4 {
        .produits {
            border: 1px solid rgba(0, 0, 0, 0.1);
            overflow: hidden;
            padding: 0;
            .prix {
                margin: 10px 0;
                width: 100%;
                /* Hack pour assurer la taille du prix, afin qu'il n'y ai pas de decallage lors de l'apparition du bouton hover */
                order: 1;
                span:not(.remise) {
                    display: inline;
                }
            }
            .container-etiquette {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: auto;
            }
            .remise {
                top: 50px;
                right: 0;
                span {
                    font-size: 16px;
                    font-weight: 600;
                }
            }
            .productImageWrap {
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                overflow: hidden;
                position: relative;
            }
            .productContentWrap {
                order: 2;
                .desc {
                    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
                    order: 4;
                }
            }
            .nomprod {
                width: 100%;
                text-align: center;
                order: 2;
                margin: 0;
            }
            .attr {
                display: none;
                height: 30px !important;
                margin: 0;

                select {
                    width: auto;
                    left: 0;
                }
            }
            .block-btn-addbasket{
                order: 1;
            }
            &:hover .productContentWrap .attr {
                display: block;
                margin: -45px auto 15px;
            }
        }
    }
    &.template_boutique_5 {
        .produits {
            flex-direction: row;
            .container-etiquette {
                width: 50%;
            }
            &:hover .prix {
                display: block;
                justify-content: center;
                text-align: center;
            }
            .desc {
                display: none;
            }
            .remise {
                left: 10px;
                right: auto;
            }
            .attr {
                display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
                margin: 15px auto;
                order: 4;
                opacity: 1;
            }
            .prix {
                order: 3;
                justify-content: center;
                height: auto;
                display: block;
                min-height: 71px;
                text-align: center;

                .oldprix {
                    width: 100%;
                    float: none;
                    margin: 5px 0;
                    line-height: 30px;
                }

                .prixprod {
                    display: block;
                    width: 100%;
                    float: none;
                    margin: 5px 0;
                }
            }
            .nomprod {
                text-align: left;
                margin: 0;
                font-size: 16px;
                justify-content: center;
                order: 2;
            }
        }

        .block-btn-addbasket{
            order: 4;
            display: flex;
            width: 200%;
            margin-left: -100%;
        }

        a.addbasket, .hors-stock {
            width: 100%;
            span {
                font-size: 15px;
            }
        }
        a.addbasket {
            .inside-addbasket {
                width: 100%;
            }
        }
        .productContentWrap {
            display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
            flex-direction: column;
            align-items: flex-start;
            box-sizing: border-box;
            width: 50%;
            padding: 0 10px;
            p {
                width: 100%;
            }
        }
        .productImageWrap {
            width: 50%;
        }
    }
}

/* ---------- Sidebar catégories ------------ */

.template_boutique_accueil {
    &.accueil_boutique_template_1,
    &.accueil_boutique_template_2 {
        margin-top: 20px;
    }
    &.accueil_boutique_template_1 {
        $largeurFleches: 0px;
        $margin: 20px;
        $nbrProduit: 4;
        @include produitAccueilTaille($largeurFleches, $nbrProduit, $margin);
        .produits{
            float: left;
        }
        .bx-wrapper .bx-controls-direction a {
            width: 50px;
            height: 50px;
            font-size: 0;
            text-indent: 0;
        }
        .bx-wrapper .produits {
            &:nth-child(n+5) {
                display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
            }
        }
        &:hover{
            .bx-controls-direction{
                a.bx-prev{
                    margin-left: 0;
                    left: 10px;
                    visibility: visible;
                    -webkit-animation: wrapimage-nav-anim .2s ease-out;
                    animation: wrapimage-nav-anim .2s ease-out;
                }
                a.bx-next{
                    margin-right: 0;
                    right: 10px;
                    visibility: visible;
                    -webkit-animation: wrapimage-nav-anim .2s ease-out;
                    animation: wrapimage-nav-anim .2s ease-out;
                }
            }
        }
        @media #{$small-and-down} {
            .bx-controls-direction{
                a.bx-prev{
                    margin-left: 0;
                    left: 10px;
                    visibility: visible;
                    -webkit-animation: wrapimage-nav-anim .2s ease-out;
                    animation: wrapimage-nav-anim .2s ease-out;
                }
                a.bx-next{
                    margin-right: 0;
                    right: 10px;
                    visibility: visible;
                    -webkit-animation: wrapimage-nav-anim .2s ease-out;
                    animation: wrapimage-nav-anim .2s ease-out;
                }
            }
        }
    }
    &.accueil_boutique_template_2 {
        .produits {
            .prix {
                margin: 15px 0;
            }
        }
    }
}

/*********************************************************/
/********************* FICHE PRODUIT *********************/
/*********************************************************/

.template_fiche_produit {
    #fiche-produit {
        .previews {
            ul li {
                border: 1px solid rgba(0, 0, 0, 0.2);
            }
            > span {
                height: 40px;
                visibility: hidden;
                transition: all .1s ease-out;
                width: auto;
                i {
                    display: none;
                }
                &::before {
                    font-family: fontAwesome, sans-serif;
                    font-size: 16px;
                    width: 40px;
                    display: block;
                    height: 40px;
                    line-height: 34px;
                    text-align: center;
                    border: 3px solid rgba(0, 0, 0, 0.2);
                    border-radius: 50%;
                    color: rgba(0, 0, 0, 0.2);
                }
                &.previous {
                    margin-left: -40px;
                    &::before {
                        content: '\f0d9';
                    }
                }
                &.next {
                    margin-right: -40px;
                    &::before {
                        content: '\f0da';
                    }
                }
            }
        }

        .brand {
            border: none !important;
            &::after {
                display: none;
            }
        }

        .triangle-ficheprod {
            right: 0;
            top: 0;
            position: absolute;
        }

        .wrap-images {
            .remise-produit {
                display: block;
                padding: 10px;
                position: absolute;
                top: 50px;
                left: 0;
                right: auto;
                border-radius: 0;
                transition: all .1s ease-out;
                height: 40px;
                font-size: 20px;
                width: auto;
                line-height: 20px;
            }
            &:hover {
                .previews > span {
                    animation: wrapimage-nav-anim .2s ease-out;
                    visibility: visible;
                    &.previous {
                        margin-left: 20px;
                    }
                    &.next {
                        margin-right: 20px;
                    }
                }
                .remise-produit {
                    left: 0;
                }
            }
            @media #{$small-and-down} {
                .previews > span {
                    animation: wrapimage-nav-anim .2s ease-out;
                    visibility: visible;
                    &.previous {
                        margin-left: 20px;
                    }
                    &.next {
                        margin-right: 20px;
                    }
                }
            }
        }

        .wrap-description {
            .row {
                h1, h3, .h1, .h3 {
                    display: none;
                }
            }
        }
        .ajouter-panier {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            order: 3;

            &.block-addbasket {
                order: 5;
            }

            .triangle-ficheprod {
                display: none;
            }

            .prix {
                display: flex;
                flex-direction: row;
                width: 100%;
                margin: 0;
                border-top: none;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                line-height: 30px;
            }
        }
        .oldprix {
            margin-left: 15px;
            order: 2;
        }
        .radio_unique {
            margin: 0 15px 0 0;
            box-shadow: none;
            border: 2px solid rgba(0, 0, 0, 0.2);
            border-radius: 0;
            &:hover {
                cursor: pointer;
            }
        }
        .radio_color {
            border: 0;
            border-radius: 20px;
            margin: 5px;
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
            &:hover, &.radio-color-checked {
                border: 2px solid transparent;
            }
        }
        .declinaison-attribute-group,.product_options-group {
            padding-top: 5px;
            padding-bottom: 5px;
            label{
                margin: 5px;
                display: inline-block;
                vertical-align: middle;
            }
            > label:first-child {
                margin: 10px 0;
                vertical-align: middle;
            }
            .declinaison-attribute-group__item,.product_options-group__item{
                display: inline-block;
                vertical-align: middle;
            }
        }

        .pro-pannier {
            display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
            flex-direction: column;
        }
        .declinaison {
            order: 1;
            margin-bottom:5px;
        }
        .declinaison-attribute-group,.product_options-group{
            label{
                font-size: .95rem;
                margin:5px 0;
            }
            select{
                vertical-align: middle;
            }
            .radio_unique, .radio_color{
                margin:5px 7px;
            }
        }
        .bloc-quantite {
            order: 2;
            box-sizing: border-box;
            margin: 5px 0 10px;
            line-height: 30px;
            input {
                border-radius: 0;
            }
            label {
                font-size: .95rem;
                margin-bottom: 0;
            }
            .notification_product {
                margin: 10px 0;
                display: inline-block;
                float: none;
                height: auto;
                &:empty{
                    display:none;
                }
            }
        }

        .description {
            font-size: 14px;
        }
        .btn-details {
            font-size: 15px;
            font-weight: 600;
            padding: 0;
            border: none;
            background: none;
        }
        a.addbasket {
            box-sizing: border-box;
            border: none;
            padding: 5px 15px;
            border-radius: 20px;
            font-weight: bold;
            text-transform: capitalize;
            position: relative;
            margin: 10px 0 10px 120px;
            font-size: 1.2rem;
            height: auto;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 20px;
                z-index: 1;
            }
            &:hover::before {
                transform: scale(1.01);
                transition: transform .2s cubic-bezier(.46, 15, .4, 15) !important;
            }
            .fa, span {
                z-index: 2;
                position: relative;
            }
            .fa {
                margin-right: 5px;
            }
        }
        a.addbasket, .hors-stock {
            display: inline-block;
            line-height: 30px;
            width: auto;
            margin: 10px 0;
        }
        .hors-stock{
            font-size: 13px;
        }
        .sociaux {
            border-bottom: none;
            text-align: left;
            iframe {
                margin: 5px 10px 5px 0 !important;
                &:first-child {
                    width: 94px !important;
                }
            }
        }
        .wrap-details > h4,
        .wrap-details > .h4 {
            text-transform: uppercase;
        }
        .details {
            margin: 20px 0;
            font-size: 16px;
        }
        .fa-comment {
            margin-right: 10px;
        }
        .bloc-avis-clients {
            .moyenne_etoiles {
                margin: 0 15px;
            }
            .msg-informations {
                width: 100%;
                border-bottom: 1px solid #ccc;
                padding-bottom: 10px;
                .noteComment {
                    float: right;
                    font-size: 20px;
                }
                span {
                    display: inline-block;
                }
                .time {
                    margin-right: 10px;
                    font-size: .9rem;
                }
                .msg-username {
                    font-weight: bold;
                }
            }
        }
    }

    &.template_fiche_produit_1 {
        #fiche-produit {
            .wrap-images {
                width: 40%;
                margin-right: 0;
            }
            .wrap-description {
                width: 60%;
                float: left;
                padding-left: 30px;
            }
            .triangle-ficheprod {
                left: 0;
                right: auto;
                top: 0;
                position: absolute;
            }
        }
    }
    &.template_fiche_produit_2 {
        #fiche-produit {
            h1, .h1 {
                margin-bottom: 10px;
            }
            #produit-comment .commentFicheProd {
                padding-right: 0;
            }
            .wrap-images {
                .remise-produit {
                    top: 0;
                    right: 0;
                    left: auto;
                }
                &:hover .remise-produit {
                    left: auto;
                }
            }
            .triangle-ficheprod {
                top: 0;
                right: 0;
            }
            .wrap-description {
                width: 35%;
                box-sizing: border-box;
                padding: 0 15px;
                .container-etiquette {
                    margin-bottom: 10px;
                }
                #addcomment {
                    width: 100%;
                    > div {
                        padding: 15px;
                        .form-group {
                            padding: 0;
                        }
                    }
                }
                .declinaison{
                    .declinaison-attribute-group > label:first-child,.product_options-group > label:first-child{
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }
    &.template_fiche_produit_3 {
        #fiche-produit {
            h1, .h1 {
                display: none;
            }
            .wrap-images .remise-produit {
                right: 0;
                left: auto;
                top: 0;
            }
            .details-descr-com {
                border: 1px solid rgba(0, 0, 0, 0.1);
                width: 65%;
            }
            .side-tabs {
                width: 100%;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                a {
                    width: 30%;
                    float: left;
                    margin: 0;
                    border-right: 1px solid rgba(0, 0, 0, 0.1);
                    border-bottom: none;
                    border-left: none;
                    border-top: none;
                    display: inline-block;
                    font-size: 16px;
                    padding: 5px 0;
                    border-radius: 0;
                }
            }
            .wrap-description {
                .prix {
                    width: auto;
                }
            }
            .bloc-quantite {
                margin: 0 0 10px;
            }
            .pro-pannier .ajouter-panier, .hors-stock {
                order: 4;
                padding-top: 10px;
                a {
                    width: auto;
                    display: inline-block;
                    margin-left: 140px;
                }
            }
            .contents-tabs {
                width: 100%;
                padding: 20px;
            }
            #addcomment > div {
                border: none;
            }
            #entete > div {
                margin: 15px;
            }
        }
    }
}

.zoomContainer {
    z-index: auto;
}

#fiche-produit .top_fiche .button {
    display: inline-block;
}

.reservation__list > * {
    flex: 1 1 auto;
}

#addcomment {
    width: 100%;
    > div {
        box-shadow: none !important;
        border: 1px solid rgba(0, 0, 0, 0.1) !important;
    }
}

/* Permet de bien afficher le hover des images fiches produits pour le zoom */
.content_p .zoomPupImgWrapper > img, .content_p .zoomWrapperImage > img {
    max-width: none !important;
}

/******************************************************/
/********************* LIVRE D'OR *********************/
/******************************************************/

.template_livreor_1 .message_lo {
    padding: 20px;
    border: 1px solid;
}

/***************************************************/
/********************* CONTACT *********************/
/***************************************************/

.template_contact {
    form {
        label {
            margin-bottom: 10px;
        }
        input[type="text"] {
            box-sizing: border-box;
            padding: 10px;
            outline: none;
        }
    }
    div.inmap {
        box-sizing: border-box;
        padding: 15px;
    }
}

/****************************************************/
/********************* GALERIES *********************/
/****************************************************/

.template_album {
    .galerie {
        .center-img {
            .mask {
                opacity: 0;
                transition: transform .2s ease-in-out, opacity .1s linear;
                transform-origin: center center;
                transform: scale(0);
            }
        }
        .commentP {
            opacity: 0;
            height: auto;
            bottom: 80%;
            top: auto;
            transition: opacity .3s ease-in-out .1s, bottom .3s ease-in-out .1s;
            z-index: 10;
            transform: none;
            &::before {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                content: '';
            }
            p {
                padding: 10px;
                font-weight: bold;
                height: auto;
                display: block;
                margin: 0;
                font-size: 1rem;
                text-align: center;
            }
        }
        .addcommentaires {
            position: absolute;
            opacity: 0;
            top: 80%;
            bottom: auto;
            height: 40px;
            width: 40px;
            line-height: 40px;
            border: 1px solid;
            border-radius: 50%;
            left: 0;
            right: 0;
            display: block;
            margin: 0 auto;
            transition: opacity .3s ease-in-out .1s, top .3s ease-in-out .1s;
            font-size: 0;
            text-align: center;
            padding: 0;
            .fa {
                font-size: 20px;
                line-height: 36px;
                margin: 0;
            }
        }
        &.sans-texte {
            .addcommentaires {
                margin-top: -20px;
            }
        }
        &.sans-commentaire {
            .commentP {
                transform: translateY(50%);
            }
        }
        &:hover {
            .center-img {
                .mask {
                    opacity: .7;
                    transform: scale(1);
                }
            }
            .commentP, .addcommentaires {
                opacity: 1;
            }
            .commentP {
                top: auto;
                bottom: 50%;
            }
            .addcommentaires {
                top: 50%;
                bottom: auto;
            }
        }
    }
    &.template_album_4, &.template_album_5 {
        .list-photos .galerie {
            .commentP {
                opacity: 1;
                height: auto;
                bottom: auto;
                top: auto;
                z-index: 10;
                display: block;
                p {
                    font-weight: bold;
                    height: auto;
                    display: block;
                    margin: 0;
                    font-size: 1rem;
                }
            }
            .addcommentaires {
                margin-top: -55px;
            }
            &.sans-texte {
                .addcommentaires {
                    margin-top: -20px;
                }
            }
            &.sans-commentaire {
                .commentP {
                    transform: translateY(0);
                }
            }
            &:hover {
                .commentP {
                    top: auto;
                    bottom: auto;
                }
            }
        }
    }
}

/**************************************************/
/********************* PANIER *********************/
/**************************************************/

.template-panier {
    &.template-panier-2 {
        border: none;
        max-height: 53px;
        #header-panier {
            padding: 18px 10px;
            font-size: 13px;
            line-height: 16px;
            text-transform: uppercase;
        }
        .fa-shopping-cart {
            font-size: 16px;
        }
        .count-live {
            top: 12px;
        }
        #paniercontent {
            border: none;
            box-shadow: -1px 3px 3px 0 rgba(0, 0, 0, 0.2);
        }
    }
    &.template-panier-3 {
        border: none;
        top: 0;
    }
    &.template-panier-4 {
        background: transparent;
        width: auto;
        border: none;
        margin: 5px 10px 0 0;
        //z-index: 10;
        vertical-align: top;
        z-index: auto;

        #panier, .connexion {
            border-radius: 50%;
        }

        #paniercontent {
            top: 100%;
            top: calc(100% + 5px);
            position: absolute;
            border: none;
            box-shadow: 0 0 5px #999;
            z-index: 20;
        }

        #header-panier {

            font-size: 0;
            padding: 0;
            line-height: 40px;

            .count-live {
                top: -2px;
                bottom: auto;
                right: -2px;
                left: auto;
                box-shadow: -1px 1px 3px 0 rgba(0, 0, 0, .5);
            }

            .panier_titre {
                font-size: 22px;
                padding: 0;
                line-height: 40px;
                height: 40px;
                width: 40px;
                text-align: center;
                margin: 0;
            }

            .paniertoggle {
                display: none;
            }
        }
        ~ #block-search-menu {
            float: none;
            display: inline-block;
        }
    }
}

.input-group .form-control {
    z-index: auto;
}

/**************************************************/
/********************* FOOTER *********************/
/**************************************************/

footer {
    padding: 20px;
    text-align: center;
    clear: both;
    width: 100%;
}

/**************************************************/
/********************* AUTRES *********************/
/**************************************************/

#minisite-sidebar.label-square.pos-left.circle.large ul li a span {
    border-radius: 0 30px 30px 0;
}

//Block recherche dans le menu secondaire
#block-search-menu {
    text-align: left;
    position: relative;
    top: 0;
    width: 300px;
    height: 40px;
    margin: 5px auto 0;
    float: right;
    //z-index: 10;
    vertical-align: top;
    animation-name: block-search;
    animation-duration: .5s;

    input, button {
        height: 40px;
    }

    input {
        border-radius: 20px 0 0 20px;;
        padding: 0 20px;
        outline: none;
    }

    button {
        border-radius: 0 20px 20px 0;
        border: none;
        font-size: 20px;
        padding: 0 15px;
        cursor: pointer;
    }
}

/*-----------------------------
    FLUX PANIER
-------------------------------*/

#flux-panier.template_flux_panier.template_flux_panier_1 {

    h4 {
        margin: 0;
    }

    div#information .list_address {
        margin: 0 0 20px;
    }
}

/*-----------------------------
    Boutons profil
-------------------------------*/

#order .commandes-details{
    .infosclient{
        td .button{
            padding: 5px;
            font-size: 14px;
        }
    }
}

/*********************************************************/
/********************* MEDIA QUERIES *********************/
/*********************************************************/

@media #{$large-and-down} {
    .wrapper, nav#nav-principal, #headercontent {
        width: $medium-screen - 80;
    }
    /* Fiche produit */
    #fiche-produit .wrap-images .previews .list-previews {
        width: 64%;
    }

    //Conteneur

    #headercontent .container-slogan,
    .header-conteneur, #nav-secondary,
    .accueil_diaporama_template .bx-controls {
        width: 100%;
    }
    #wrapper {
        width: 100%;
        padding: 20px 20px;
        box-sizing: border-box
    }
    #pub {
        width: 100%;
    }
    #blocContent {
        padding: 15px;
    }

    //Diaporama
    .accueil_diaporama_template {
        width: 100%;
    }

    //Panier
    .template-panier {
        &.template-panier-3 {
            top: -15px;
        }
    }

    //Menus

    nav#nav-principal {
        width: auto;
    }
    #nav-secondary {
        padding: 0 15px;
    }
    .sidebar {
        width: 20%;
        float: left;
        ~ .content nav.nav-static-breadcrumb {
            margin-left: -29%;
        }
        ~ .content {
            width: 75%;
            float: right;
            margin-top: 0;
        }
    }
}

@media #{$medium-and-down} {
    #headercontent {
        height: auto !important;
    }
    .slogan {
        position: relative !important;
        left: 0 !important;
        top: 0 !important;
        margin-top: 15px;
        text-align: center !important;
        font-size: 1.2rem !important;
        display: block;
    }
    #logo {
        position: relative !important;
        width: 100% !important;
        text-align: center !important;
        left: 0 !important;
        top: 0 !important;
        margin-top: 15px;
        display: block;
        img {
            width: auto !important;
            max-height: 60px;

        }
    }
    .wrapper, #headercontent {
        width: $small-screen;
    }
    /*Suppression du bloc de recherche a droite*/
    #block-flottant-search {
        display: none;
    }
    /* Met une position par defaut du logo et du slogan */
    .slogan {
        position: relative !important;
        left: 0 !important;
        top: 0 !important;
        margin-top: 15px;
        text-align: center !important;
        font-size: 1.2rem !important;
        display: block;
    }
    #logo {
        position: relative !important;
        width: 100% !important;
        text-align: center !important;
        left: 0 !important;
        top: 0 !important;
        margin-top: 15px;
        display: block;
        img {
            width: auto !important;
            max-height: 60px;

        }
    }
    /* Fiche produit */
    #fiche-produit .wrap-images .previews .list-previews {
        width: 49%;
    }

    //Template panier
    .cmonsite-panier-2 .header-conteneur #nav-principal > .menu_burger {
        padding-right: 20px;
        float: left;
    }
    .template-panier {

        &.template-panier-2 {
            top: 0;
            position: static;
            height: 40px;
            margin-top: 5px;
            max-height: 40px;
            width: 40px;
            float: left;
            border-radius: 50%;
            margin-left: 10px;
            #panier {
                border-radius: 50%;
                position: static;
            }
            #paniercontent {
                left: 0;
                right: auto;
                top: calc(100% + 50px);
                min-width: 350px;
            }
            #header-panier {
                position: relative;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                padding: 0;
                line-height: 40px;
                overflow: visible;
                text-indent: 0;
                top: 0;
                font-size: 0;
                .count-live {
                    left: auto;
                    top: 0;
                    right: 0;
                    bottom: auto;
                }
                .fa-shopping-cart {
                    font-size: 20px;
                    text-indent: 0;
                    margin: 0;
                }
            }
            .paniertoggle i.fa {
                font-size: 0;
            }
        }
        &.template-panier-3 {
            #blocContent {
                #paniercontent {
                    min-width: 350px;
                }
                #headercontent {
                    padding-bottom: 20px;
                }
                .template-panier.template-panier-3 {
                    width: 100%;
                    max-width: 300px;
                    top: auto;
                    right: auto;
                    left: auto;
                    border: 1px solid;
                    margin: 10px auto 0;
                    position: static;
                    float: none;
                    height: auto;
                    clear: both;
                    &.sans-connexion {
                        width: 100%;
                    }
                    .connexion {
                        width: 100%;
                        padding: 0;
                        p {
                            border: none;
                        }
                    }
                    #header-panier {
                        text-align: center;
                        position: relative;
                    }
                    .count-live {
                        left: auto;
                        top: auto;
                        margin-left: 10px;
                    }
                    .bloc-connexion-panier {
                        #panier {
                            position: static;
                            width: 100%;
                            #paniercontent {
                                min-width: 0;
                                left: 0;
                                right: 0;
                                width: 100%;
                                max-width: 100%;
                                box-sizing: border-box;
                                margin-top: -21px;
                                z-index: 9999;
                                top: 100%;
                            }
                            .paniertoggle {
                                float: none;
                                position: absolute;
                                top: 0;
                                right: 0;
                                width: 100%;
                                height: 100%;
                                .fa {
                                    position: absolute;
                                    right: 10px;
                                    top: 50%;
                                    margin-top: -8px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    //Header

    header {
        height: auto;
        .header-conteneur .menu {
            width: auto;
            max-width: none;
            margin-top: 0;
            position: static;
            right: auto;
            float: left;
        }
    }
    #blocContent {
        float: none;
        position: relative;
    }
    #headercontent .container-slogan {
        position: relative;
        margin-top: 20px;
    }

    //Conteneur

    .wrapper {
        width: 100%;
    }
    .content {
        width: 100%;
        padding: 0;
    }

    //Diaporama

    .accueil_diaporama_template {
        width: 100%;
        .bx-wrapper .bx-viewport, .slide > div, .slide > li, .center-img-slider img {
            max-height: 400px;
        }
        .button_slider {
            display: none;
        }

        &.accueil_diaporama_template_2 {
            .slide {
                .content-slide-text p {
                    padding-left: 50px;
                    max-width: 60%;
                    font-size: 15px;
                }
            }
        }
        &.accueil_diaporama_template_3 {
            .slide {
                .center-img-slider img {
                    max-height: 400px;
                }
                .content-slide-text > p {
                    bottom: 0;
                }
            }
        }
        &.accueil_diaporama_template_4 {
            max-height: 400px;
            padding: 0;
            .slide {
                li {
                    max-height: 400px;
                    .center-img-slider {
                        font-size: 0;
                        margin: 0;
                        img {
                            min-height: 400px;
                            max-height: 400px;
                        }
                    }
                }
            }

        }
        &.accueil_diaporama_template_5 {
            height: 250px;
        }
    }

    //Menus

    .menu #nav-principal > ul > li > a > span {
        border: none;
    }

    nav#nav-principal {
        position: static;

        > ul > li > a > span {
            border: none;
        }

        > ul ul {
            visibility: visible;
            opacity: 1;
            position: relative;
        }

        li > ul li:first-child > a {
            padding-top: 0;
        }

        .menu_burger {
            width: 68px;
            height: 50px;
            position: relative;
            top: 0;
            padding: 0 20px;
            line-height: 50px;
            float: left;

            &::before {
                content: '\f036';
                font-family: "fontAwesome", sans-serif;
                font-size: 28px;
                font-weight: normal;
                margin-right: 10px;
                color: inherit;
            }
        }
        li > ul {
            box-shadow: none;
            transition: none;
            animation: none;
            margin-top: 0;

            &:hover > ul {
                transition: none;
            }

            li {
                padding: 15px;

                &:hover > ul {
                    transition: none;
                }

                &:last-child {
                    border: none;
                }

                ul {
                    left: 0;
                }
            }
        }
        > ul {
            position: absolute;
            display: none;
            left: 0;
            right: 0;
            top: 100%;
            transition: none;
            padding-right: 0;
            margin-right: 0;
            box-shadow: -1px 3px 3px 0 rgba(0, 0, 0, 0.2);
            margin-top: 50px !important;
            ul {
                opacity: 1;
                visibility: visible;
                margin: 0;
                position: relative;
            }
        }
        ul {
            display: none;
        }
        li {
            width: 100%;
            text-align: left;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            span.dropDown {
                position: absolute;
                right: 15px;
                width: 60px;
                top: 10px;
                height: 35px;
                text-align: center;
                line-height: 25px;
                cursor: pointer;

                &::before {
                    font-size: 18px;
                }
            }
        }
    }

    #nav-secondary {
        padding: 0;
        > ul {
            display: none !important;
        }
        .menu-title {
            display: none;
        }
        #block-search-menu {
            width: 250px;
            right: 5px;
        }
    }

    //Fiches produits

    .template_fiche_produit {
        #fiche-produit {
            .wrap-images {
                width: 100%;
                .container-etiquette {
                    left: 0;
                }
                .remise-produit {
                    top: 50px;
                }
            }
            .wrap-description {
                width: 100%;
                padding: 0;
                margin-top: 25px;
            }
        }
        &.template_fiche_produit_1 {
            #fiche-produit {
                .wrap-description, .wrap-images {
                    width: 100%;
                    padding-left: 0;
                }
            }
        }
        &.template_fiche_produit_2 {
            #fiche-produit {
                .wrap-images .remise-produit {
                    right: -10px;
                }
                .wrap-description .container-etiquette {
                    width: 85%;
                    left: auto;
                    right: 0;
                }
                .wrap-description {
                    width: 100%;
                    padding: 0;
                    margin-top: 25px;
                }
            }
        }
    }

    //Boutique

    .template_boutique_all {
        .produits {
            .block-btn-addbasket {
                display: flex;
                order: 4;
            }
            .attr, &:hover .attr {
                display: flex;
                opacity: 1;
            }
            &:hover .prix {
                display: flex;
            }
        }
        &.template_boutique_4 {
            .produits {
                .productContentWrap .desc {
                    text-align: justify;
                }
            }
        }
        &.template_boutique_5 {
            .produits {
                margin: 20px 0;
                .productContentWrap {
                    p.prix {
                        min-height: 40px;
                    }
                    a.addbasket {
                        width: 100%;
                        margin-left: 0;
                    }
                }
            }
        }
    }

    /* Affichage du bouton ajouter au panier en responsive */

    .template_boutique_accueil {
        .produits {
            .attr, &:hover .attr {
                display: flex;
            }
        }

        &.accueil_boutique_template_1 {
            $largeurFleches: 0px;
            $margin: 20px;
            $nbrProduit: 2;
            @include produitAccueilTaille($largeurFleches, $nbrProduit, $margin);
            .bx-wrapper .produits:nth-child(n+3) {
                display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
            }
        }
    }

    //Ariane / sidebar
    #minisite-sidebar {
        display: none;
    }
    .sidebar {
        display: none;
        ~ .content nav.nav-static-breadcrumb {
            margin-left: 0;
        }
        ~ .content {
            width: 100%;
            float: none;
            margin-top: 0;
        }
    }
    #wrapper nav.nav-static-breadcrumb {
        line-height: 40px;
    }

    //Fiche produits
    .template_fiche_produit {
        &.template_fiche_produit_3 {
            #fiche-produit {
                .details-descr-com {
                    width: 100%;
                }
            }
        }
    }

    //Livre d'or

    .livre_dor .titre_page h2,
    .livre_dor .titre_page .h2 {
        margin: 10px 0;
    }
}

@media #{$small-and-down} {
    .wrapper, #headercontent {
        width: 90%;
    }

    /* Tableau responsive */
    @import '../../design/helper/responsive/mobile/mobile';

    //Diaporama

    .accueil_diaporama_template {
        .bx-wrapper .bx-viewport, .slide > div, .slide > li, .center-img-slider img {
            max-height: 300px;
        }
        .button_slider {
            display: none;
        }
        &.accueil_diaporama_template_3 {
            .slide {
                .center-img-slider img {
                    max-height: 300px;
                }
            }
        }
        &.accueil_diaporama_template_4 {
            max-height: 400px;
            padding: 0;
            .slide {
                li {
                    max-height: 400px;
                    .center-img-slider {
                        font-size: 0;
                        margin: 0;
                        img {
                            min-height: 300px;
                            max-height: 300px;
                        }
                    }
                }
            }
            .button_slider {
                display: none;
            }
        }
        &.accueil_diaporama_template_5 {
            height: 200px;
        }
    }

    //Template panier

    .template-panier {
        &.template-panier-2 {
            #paniercontent {
                min-width: 0;
                width: 100%;
            }
        }
        &.template-panier-3 {
            .count-live {
                left: 41px;
            }
            .paniertoggle {
                width: 100%;
                height: 100%;
            }
        }
        &.template-panier-4 {
            position: static;
            #panier {
                position: static;
            }
            #header-panier {
                position: relative;
                overflow: visible;
                z-index: 10;
            }
            #paniercontent {
                min-width: 0;
                width: 100%;
                top: 100%;
                z-index: 1010;
            }
        }
    }

    // Fiche produits

    .template_fiche_produit {
        &.template_fiche_produit_2 {
            #fiche-produit {
                .wrap-images .remise-produit {
                    right: 0;
                }
                .wrap-description .container-etiquette {
                    width: 100%;
                    left: auto;
                    right: 0;
                }
            }
        }
        &.template_fiche_produit_3 {
            #fiche-produit {
                .side-tabs a {
                    font-size: 13px;
                }
            }
        }
    }

    /* Produits */

    .template_boutique {
        &.template_boutique_3 {
            .produits {
                .container-etiquette, .productImageWrap, .productContentWrap {
                    width: 100%;
                    float: none;
                }
                .productContentWrap {
                    width: 100%;
                    .nomprod {
                        text-align: center;
                    }
                    .desc {
                        text-align: justify;
                    }
                }
            }
        }
        &.template_boutique_5 {
            .produits {
                .productImageWrap {
                    width: 50%;
                }
            }
        }
    }
    .template_boutique_accueil {
        &.accueil_boutique_template_1 {
            $largeurFleches: 0px;
            $margin: 0px;
            $nbrProduit: 1;
            @include produitAccueilTaille($largeurFleches, $nbrProduit, $margin);
            .bx-wrapper .produits:nth-child(n+2) {
                display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
            }
        }
    }

    /* Galerie d'image responsive */
    .template_album {
        .galerie {
            .addcommentaires, .commentP, .center-img .mask {
                opacity: 1;
            }
            .addcommentaires {
                position: absolute;
                right: 5px;
                top: 5px;
                left: auto;
                bottom: auto;
            }
            .center-img .mask {
                width: 50px;
                height: 50px;
                position: absolute;
                top: 0;
                right: 0;
                left: auto;
                bottom: auto;
                transform: scale(1);
            }
            .commentP {
                bottom: 0;
                .mask {
                    display: none;
                }
                &::before {
                    display: block;
                }
            }
            .mask.animMask {
                display: none;
            }
            &.sans-texte {
                .addcommentaires {
                    margin-top: 0;
                }
            }
            &.sans-commentaire {
                .commentP {
                    transform: translateY(1);
                }
            }
            &:hover {
                .center-img .mask {
                    opacity: 1;
                }
                .commentP {
                    bottom: 0;
                }
                .addcommentaires {
                    top: 5px;
                }
            }
        }
        &.template_album_3 {
            .list-photos .galerie {
                .commentP {
                    &::before {
                        display: block;
                        z-index: -1;
                    }
                }
            }
        }
        &.template_album.template_album_4, &.template_album.template_album_5 {
            .list-photos .galerie {
                .addcommentaires, &.sans-texte .addcommentaires {
                    margin-top: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 460px) {
    #nav-secondary #block-search-menu, #nav-secondary .template-panier-4 ~ #block-search-menu {
        display: none;
    }
}

a {

}